/*----------intial declartaion of css----------*/
@-ms-viewport {
	width: device-width !important;
}
html {
	overflow-y: auto;
	-ms-overflow-style: -ms-autohiding-scrollbar !important;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
select,
input,
option {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
}
html {
	-webkit-text-size-adjust: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
	display: block;
}
audio,
canvas,
video {
	display: inline-block;
}
audio:not([controls]) {
	display: none;
	height: 0;
}
pre {
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
}
q {
	quotes: none;
}
q:before,
q:after {
	content: '';
	content: none;
}
small {
	font-size: 80%;
}
sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
sup {
	top: -0.5em;
}
sub {
	bottom: -0.25em;
}
button,
input,
select,
textarea {
	font-size: 100%;
	margin: 0;
	vertical-align: baseline;
}
textarea {
	overflow: auto;
	vertical-align: top;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
button,
html input,
textarea,
input[type='button'],
input[type='reset'],
input[type='submit'] {
	-webkit-appearance: none;
	border-radius: 0px;
	-webkit-border-radius: 0px;
}
:focus {
	outline: 0;
}
ol,
ul {
	margin: 20px 0 20px 16px;
}
li {
	font-size: 18px;
}
table {
	border-collapse: separate;
	border-spacing: 0;
}
caption,
th,
td {
	text-align: left;
	font-weight: normal;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
}
blockquote,
q {
	quotes: '' '';
}
table {
	border: 0 none;
	border-collapse: collapse;
	border-spacing: 0;
}
td {
	vertical-align: top;
}
img {
	border: 0 none;
	max-width: 100%;
}
.none {
	display: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
	display: block;
}
.clear {
	clear: both;
}
img {
	max-width: 100%;
}
.cf:before,
.cf:after {
	content: '';
	display: table;
}
.cf:after {
	clear: both;
}
.cf {
	*zoom: 1;
}
::selection {
	background: #3a3a3a;
	color: #fff;
}
::-moz-selection {
	background: #3a3a3a;
	color: #fff;
}
a {
	text-decoration: none;
	color: inherit;
}

p {
	font-size: 20px;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	margin-bottom: 9px;
	line-height: 1.5;
	letter-spacing: -0.15px;
}
h1 {
	font-size: 60px;
	margin-bottom: 23px;
	font-family: 'Roboto', sans-serif;
	line-height: 1.15;
	color: #02aca0;
	font-weight: 500;
}
h2 {
	font-size: 40px;
	margin-bottom: 25px;
	font-family: 'Roboto', sans-serif;
	line-height: 1.35;
	color: #02aca0;
	font-weight: 300;
	letter-spacing: 1.5px;
}
h3 {
	font-size: 30px;
	margin-bottom: 20px;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	color: #000000;
	letter-spacing: -0.2px;
}
h4 {
	font-size: 24px;
	margin-bottom: 20px;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	color: #02aca0;
}
h5 {
	font-size: 18px;
	margin-bottom: 10px;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	color: #000000;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	line-height: 1;
}
h6 {
	font-size: 14px;
	margin-bottom: 20px;
	font-weight: 400;
}

a,
input,
textarea {
	transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	outline: none !important;
}
a:hover,
a:focus,
input,
input:focus {
	text-decoration: none;
	outline: none;
}
a:hover,
a:focus {
	color: #02aca0;
}
p a {
	text-decoration: underline;
}

html {
	font-size: 62.5%;
}
body {
	line-height: 1.55;
	margin: 0;
	padding: 0;
	background: #fff;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
	font-size: 18px;
	color: #000000;
	font-size: 20px;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
}

*:focus {
	outline: none;
}

input[type='text'],
input[type='tel'],
input[type='email'],
textarea {
	background: #fff;
	border: none;
	padding: 16px 26px;
	width: 100%;
	border-radius: 0;
	line-height: 20px;
	display: inline-block;
	vertical-align: middle;
	font-size: 18px;
	color: #444;
	font-weight: 400;
}
input[type='text']:focus,
input[type='tel']:focus,
input[type='email']:focus,
textarea:focus {
	background-color: transparent;
	color: #000;
}

button,
html input[type='button'],
input[type='submit'] {
	display: inline-block;
	vertical-align: middle;
	font-size: 24px;
	background: #02aca0;
	color: #fff;
	text-transform: uppercase;
	line-height: 26px;
	border-radius: 50px;
	box-shadow: 0px 6px 20px 0px rgba(2, 172, 160, 1);
	padding: 13px 10px;
	text-align: center;
	min-width: 313px;
	font-weight: 500;
	letter-spacing: 1.5px;
	transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	cursor: pointer;
}
button:hover input[type='button']:hover,
input[type='submit']:hover,
button:focus input[type='button']:focus,
input[type='submit']:focus,
.btn:hover,
.btn:focus {
	background: #00baff;
	box-shadow: none;
}

input::-moz-placeholder {
	opacity: 1 !important;
}
input:-moz-placeholder {
	opacity: 1 !important;
}
input::-webkit-input-placeholder {
	color: #444 !important;
	opacity: 1 !important;
}
input:-ms-input-placeholder {
	opacity: 1 !important;
}
textarea {
	height: 100px;
	resize: none;
}

textarea::-moz-placeholder {
	opacity: 1 !important;
}
textarea:-moz-placeholder {
	opacity: 1 !important;
}
textarea::-webkit-input-placeholder {
	color: #444 !important;
	opacity: 1 !important;
}
textarea:-ms-input-placeholder {
	opacity: 1 !important;
}

@media (min-width: 1065px) {
	.container {
		max-width: 1025px;
	}
}

.btn-extra-space {
	margin-bottom: 40px;
}

.btn {
	display: inline-block;
	vertical-align: middle;
	font-size: 24px;
	background: #02aca0;
	color: #fff;
	text-transform: uppercase;
	line-height: 26px;
	border-radius: 50px;
	-webkit-box-shadow: 0px 6px 20px 0px rgba(2, 172, 160, 1);
	-moz-box-shadow: 0px 6px 20px 0px rgba(2, 172, 160, 1);
	box-shadow: 0px 6px 20px 0px rgba(2, 172, 160, 1);
	padding: 13px 10px;
	text-align: center;
	min-width: 313px;
	font-weight: 500;
	letter-spacing: 1.5px;
	transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
}
.btn.focus,
.btn:focus,
.btn:hover {
	color: #fff;
	outline: none !important;
	box-shadow: 0px 6px 20px 0px #00baff;
	background: #00baff;
}
/***** Header style starts here *****/
.header-main {
	padding: 30px 18px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 11;
}

.header-wrap {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.logo {
	width: 147px;
	display: inline-block;
	vertical-align: top;
}
.logo a {
	display: inline-block;
	vertical-align: top;
}
.logo a img {
	display: block;
}
.logo a img.mobile-logo {
	display: none;
}

.header-navigation {
	width: calc(100% - 147px);
}
.navigation-wrap {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.site-navigation {
	display: inline-block;
	vertical-align: top;
	width: 85%;
	padding: 10px 0 10px 35px;
}
.site-navigation ul {
	margin: 0;
}
.site-navigation ul li {
	list-style: none;
	display: inline-block;
	padding-right: 0px;
}
.site-navigation ul li:last-child {
	padding-right: 0;
}
.site-navigation ul li a,
.site-navigation ul li span {
	display: inline-block;
	vertical-align: top;
	line-height: 1;
	padding: 0 40px 11px;
}
.site-navigation ul > li > .active {
	color: #02aca0;
}

.lang-dropdown {
	text-align: right;
	padding-bottom: 13px;
	width: 15%;
}
.lang-label {
	display: inline-block;
	vertical-align: middle;
	min-width: 190px;
	position: relative;
}
.lang-label label {
	display: block;
	position: relative;
	border-bottom: 1px solid #000;
	padding: 11px 37px 12px 15px;
	line-height: 1;
	cursor: pointer;
	font-size: 18px;
}
.lang-label label:after {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 6px 6px 0 6px;
	border-color: #000000 transparent transparent transparent;
	content: '';
	position: absolute;
	bottom: 0;
	top: -1px;
	right: 14px;
	margin: auto;
}
#menu-toggle {
	display: none;
}
.lang-list {
	position: absolute;
	left: 0;
	width: 100%;
	background: #fff;
}
/* #menu-toggle:checked + label + .lang-list{display: block;} */
.open-language .lang-label label {
	background: #fff;
}
.lang-list ul {
	margin: 0;
}
.lang-list ul li {
	list-style: none;
}
.lang-list li a {
	display: block;
	line-height: 1;
	padding: 12px 38px 11px;
	border-bottom: 1px solid #d9d9d9;
}
.lang-list li:last-child a {
	border-bottom: none;
}
.lang-list li a:hover,
.lang-list li a:focus {
	background: #02aca0;
	color: #fff;
}
/***** Header style ends here *****/

/***** Footer style starts here *****/
.footer-main {
	background: #3a3a3a;
	color: #fff;
	padding: 59px 0 0;
	line-height: 1.5;
}
.footer-main ul {
	margin: 0;
}
.footer-main ul li {
	list-style: none;
	display: block;
	margin-bottom: 7px;
}
.footer-main ul li a {
	font-size: 21px;
	font-weight: 400;
	display: inline-block;
	vertical-align: top;
	line-height: 1;
	text-decoration: underline;
}

.footer-top,
.footer-bottom {
	padding: 0 40px;
}
.footer-top {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.footer-col {
	min-width: 17%;
	max-width: 25%;
}
.footer-col:nth-child(2) {
	min-width: 21%;
}
.footer-col:last-child {
	margin-left: auto;
	text-align: right;
}
.footer-col > a {
	display: inline-block;
	vertical-align: middle;
	margin: 15px 20px;
}

.footer-bottom {
	padding-top: 49px;
	padding-bottom: 68px;
}
.footer-bottom p {
	margin: 0;
	width: 73.5%;
	font-size: 18px;
}

.footer-copyright {
	background: #000;
	padding: 7px 55px 6px;
}
.footer-copyright p {
	margin: 0;
	font-size: 16px;
	font-weight: 400;
	line-height: 1;
}
.footer-copyright p span {
	font-size: 23px;
	margin-right: 6px;
	display: inline-block;
	vertical-align: middle;
	line-height: 1;
}
/***** Footer style ends here *****/

/***** HomePage style starts here *****/
.main-banner {
	padding: 116px 0 0 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	background: rgb(223, 223, 223);
	background: -moz-linear-gradient(
		-90deg,
		rgba(223, 223, 223, 1) 0%,
		rgba(255, 253, 253, 1) 100%
	);
	background: -webkit-linear-gradient(
		-90deg,
		rgba(223, 223, 223, 1) 0%,
		rgba(255, 253, 253, 1) 100%
	);
	background: linear-gradient(
		-90deg,
		rgba(223, 223, 223, 1) 0%,
		rgba(255, 253, 253, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dfdfdf",endColorstr="#fffdfd",GradientType=1);
}
.main-banner h1 {
	font-size: 72px;
	font-weight: 700;
	letter-spacing: -0.4px;
	margin-bottom: 17px;
	font-weight: 700;
}
.main-banner h1 span {
	font-weight: 300;
}
.main-banner p {
	font-size: 24px;
	font-weight: 500;
}
.main-banner .btn {
	margin-top: 62px;
}

.banner-left {
	min-height: 614px;
	padding-right: 25px;
}
.banner-wrap {
	width: 100%;
	padding-top: 171px;
}
.banner-wrap .container,
.banner-wrap .row {
	height: 100%;
}

.banner-right {
	margin-top: auto;
	text-align: center;
}
.banner-right img {
	display: block;
	margin: 0 auto;
}

.blue-content-box {
	color: #fff;
	background: rgb(0, 172, 159);
	background: -moz-linear-gradient(
		0deg,
		rgba(0, 172, 159, 1) 0%,
		rgba(0, 150, 214, 1) 100%
	);
	background: -webkit-linear-gradient(
		0deg,
		rgba(0, 172, 159, 1) 0%,
		rgba(0, 150, 214, 1) 100%
	);
	background: linear-gradient(
		0deg,
		rgba(0, 172, 159, 1) 0%,
		rgba(0, 150, 214, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00ac9f",endColorstr="#0096d6",GradientType=1);
	/*	text-align: center;*/
	padding: 87px 0 98px;
}
.blue-content-box h2 {
	color: #fff;
	margin-bottom: 23px;
}
.blue-content-box h2 span {
	/*
	font-size: 56px;
	letter-spacing: 8px;
	text-transform: uppercase;
*/
}
.blue-content-box p {
	/*
	font-size: 32px;
	width: 70%;
	margin: 0 auto;
	line-height: 1.35;
*/
}

.service-sec {
	padding: 80px 0 49px;
}
.icon-col {
	text-align: center;
	max-width: 490px;
	margin: 55px auto;
}
.col-img {
	display: block;
	width: 141px;
	height: 141px;
	background: #ffb405;
	border-radius: 100%;
	line-height: 134px;
	text-align: center;
	margin: 0 auto 35px;
}
.col-img img {
	height: auto;
	max-width: 93px;
	max-height: 99px;
}
.icon-col h4 {
	margin-bottom: 12px;
}
.icon-col p {
	margin-bottom: 0;
}
.icon-col p small {
	font-weight: 300;
	font-size: 12px;
	color: #9b9b9b;
}

.single-ic-sec {
	background: #f9f8f8;
	position: relative;
	z-index: 1;
	overflow: hidden;
}
.single-ic-sec .image-right-wrap:after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 198px;
	background: #02aca0;
	width: 1000%;
	transform: skewX(-42.7deg);
	z-index: -1;
}
.single-ic-sec ol {
	margin: 107px 0 0;
	list-style: none;
	counter-reset: counter;
	padding-right: 100px;
}
.single-ic-sec ol li {
	list-style: none;
	display: block;
	counter-increment: counter;
	position: relative;
	margin-bottom: 10px;
	line-height: 1.2;
	letter-spacing: -0.1px;
}
.single-ic-sec ol li:before {
	content: '(' counter(counter) ')';
	margin-right: 13px;
}

.content-left {
	padding: 99px 0 27px;
}
.content-left li {
	font-size: 12px;
	font-weight: 300;
	color: #9b9b9b;
}

.image-right-wrap {
	padding-top: 140px;
}
.image-right {
	width: 301px;
	height: 301px;
	background: #ffb405;
	border-radius: 100%;
	margin: 0px auto 0;
	text-align: center;
	line-height: 300px;
}
.image-right img {
	max-width: 187px;
}

.content-sec {
	text-align: center;
	padding: 85px 0 60px;
}
.content-sec .col-12 {
	padding: 0 182px;
}
.content-inner {
	padding-bottom: 128px;
	position: relative;
}
.content-inner:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 10px;
	background: #02aca0;
	height: 5px;
}
.content-sec h2 {
	margin-bottom: 28px;
}
.content-inner > .btn {
	margin-top: 75px;
}

.two-col-section {
	padding: 50px 0 181px;
}
.two-col-section h2 {
	margin-bottom: 50px;
}
.left-col {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
}
.right-content {
	width: calc(100% - 44px);
	padding: 0px 0 0 41px;
}
.ic-image {
	width: 44px;
	padding-bottom: 38px;
}
.two-col-section h5 + h3 {
	margin-top: 27px;
}
.two-col-section .download-link {
	margin-top: 38px;
}
.download-link {
	font-size: 16px;
	display: inline-block;
	vertical-align: middle;
	text-decoration: underline;
	line-height: 1;
	color: #02aca0;
}
.download-link img {
	display: inline-block;
	vertical-align: middle;
	margin-right: 8px;
	margin-top: -6px;
}
.download-link:hover,
.download-link:focus {
	color: #000;
	text-decoration: underline;
}
.right-col .download-link {
	margin-top: 13px;
}
.right-col p {
	font-weight: 500;
}
.right-col .download-link + h5 {
	margin-top: 39px;
}
.custom-scrollbar {
	max-height: 373px;
}
.two-col-section .simplebar-scrollbar {
	width: 6px;
	right: -2px;
}
.two-col-section .simplebar-scrollbar:before {
	background: #dcdbdb;
}
/***** HomePage style ends here *****/

/***** FAQPage style starts here *****/
.inner-banner {
	padding: 127px 0 74px;
	background: rgb(223, 223, 223);
	background: -moz-linear-gradient(
		-90deg,
		rgba(223, 223, 223, 1) 0%,
		rgba(255, 253, 253, 1) 100%
	);
	background: -webkit-linear-gradient(
		-90deg,
		rgba(223, 223, 223, 1) 0%,
		rgba(255, 253, 253, 1) 100%
	);
	background: linear-gradient(
		-90deg,
		rgba(223, 223, 223, 1) 0%,
		rgba(255, 253, 253, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dfdfdf",endColorstr="#fffdfd",GradientType=1);
	text-align: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.inner-banner .container {
	min-height: 401px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}
.inner-banner .row {
	width: 100%;
}
.inner-banner h1 {
	margin-bottom: 32px;
	line-height: 1.3;
	font-weight: 600;	
    letter-spacing: -0.4px;
}
.inner-banner p {
	font-size: 24px;
	width: 70%;
	margin-left: auto;
	margin-right: auto;
	line-height: 1.4;
	font-weight: 500;
}

.faq-main {
	padding: 80px 0 112px;
}
.accordian-col {
	width: calc(100% - 40px);
	margin: 0 auto;
	padding: 0;
	margin-bottom: 22px;
	border-radius: 30px;
}
.accordian-col:last-child {
	margin-bottom: 0;
}
.accordian-title {
	background: #02aca0;
	color: #fff;
	border-radius: 50px;
	padding: 27px 35px 23px;
	cursor: pointer;
	position: relative;
	z-index: 1;
}
.accordian-title:before,
.accordian-title:after {
	content: '';
	position: absolute;
	top: 0;
	right: 36px;
	bottom: 0;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 28px;
	transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	width: 28px;
}
.accordian-title.active {
	background: rgb(0, 172, 159);
	background: -moz-linear-gradient(
		90deg,
		rgba(0, 172, 159, 1) 0%,
		rgba(0, 150, 214, 1) 100%
	);
	background: -webkit-linear-gradient(
		90deg,
		rgba(0, 172, 159, 1) 0%,
		rgba(0, 150, 214, 1) 100%
	);
	background: linear-gradient(
		90deg,
		rgba(0, 172, 159, 1) 0%,
		rgba(0, 150, 214, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00ac9f",endColorstr="#0096d6",GradientType=1);
	-webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00ac9f",endColorstr="#0096d6",GradientType=1);
}
.accordian-title:before {
	background-image: url('../images/plus-ic.svg');
}
.accordian-title:after {
	background-image: url('../images/minus-ic.svg');
	opacity: 0;
}
.accordian-col .accordian-title.active:before {
	opacity: 0;
}
.accordian-col .accordian-title.active:after {
	opacity: 1;
}
.accordian-title h4 {
	color: inherit;
	font-size: 26px;
	margin: 0;
	line-height: 1;
	padding-right: 40px;
}

.accordian-content {
	border-radius: 40px;
	max-height: 0;
	transition: all 0.4s ease-out;
	overflow: hidden;
	padding: 76px 35px 0;
	margin-top: -76px;
	background: #f8f7f7;
}
.accordian-title.active + .accordian-content {
	padding: 102px 35px 35px;
	max-height: 500px;
	transition: all 0.5s ease-in;
}
/***** FAQPage style ends here *****/

/***** SupportPage style starts here *****/
.blue-content-box.inner-blue-box p {
	text-align: center;
	font-size: 20px;
	width: 61%;
	line-height: 1.5;
}

.sub-info-col {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	text-align: left;
	padding: 96px 0 0;
}
.info-col {
	width: 50%;
	padding-left: 100px;
}
.info-col h3 {
	margin-bottom: 33px;
}
.hcp-col {
	margin-bottom: 33px;
}
.hcp-col-tra {
	margin-bottom: 18px;
}
.hcp-col-small {
	margin-bottom: 33px;
	color: #000000;
	font-weight: 200;
}
.hcp-col-main {
	margin-bottom: 0;
}
.info-col h4 {
	font-size: 20px;
	color: #000000;
	font-weight: 500;
	line-height: 1.5;
	display: block;
	margin-bottom: 10px;
}
.info-col .download-link + h4 {
	margin-top: 45px;
}
.info-col p {
	margin-bottom: 27px;
}
.info-col .download-link {
	margin-top: 20px;
}
.info-col a {
	display: table;
	margin-bottom: 20px;
}
.info-col a:last-child {
	margin-bottom: 0;
}
.info-col .btn {
	display: inline-block;
	vertical-align: middle;
	margin-top: 16px;
	margin-bottom: 0;
}

.support-sec {
	padding: 105px 0 70px;
}
.support-inner {
	text-align: center;
	margin: 0 auto;
	width: 65%;
}
.content-wrap {
	position: relative;
	padding-bottom: 144px;
}
.content-wrap:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 5px;
	background: #02aca0;
	width: 64%;
	margin: 0 auto;
	border-radius: 10px;
}

.support-sec + .support-sec {
	padding-top: 50px;
}
.support-sec + .support-sec .content-wrap {
	padding-bottom: 315px;
}
.support-sec + .support-sec .content-wrap:after {
	display: none;
}
.info-col .btn + h4 {
	margin-top: 67px;
}
.info-link a:first-child {
	text-decoration: underline;
	color: #02aca0;
}
.info-link a:first-child:hover,
.info-link a:first-child:focus {
	color: #000;
}
/***** SupportPage style ends here *****/

/***** HistoryPage style starts here *****/
.history-page {
	padding-top: 116px;
	background: rgb(223, 223, 223);
	background: -moz-linear-gradient(
		180deg,
		rgba(255, 253, 253, 1) 0%,
		rgba(223, 223, 223, 1) 100%
	);
	background: -webkit-linear-gradient(
		180deg,
		rgba(255, 253, 253, 1) 0%,
		rgba(223, 223, 223, 1) 100%
	);
	background: linear-gradient(
		180deg,
		rgba(255, 253, 253, 1) 0%,
		rgba(223, 223, 223, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dfdfdf",endColorstr="#fffdfd",GradientType=1);
}
.history-head {
	text-align: center;
	padding: 126px 0 175px;
	margin: 0 auto;
	width: 63%;
	position: relative;
}
.history-head:after {
	content: '';
	position: absolute;
	bottom: 0;
	height: 5px;
	left: 0;
	right: 0;
	background: #02aca0;
	margin: 0 auto;
	width: 78%;
	border-radius: 10px;
}
.history-head h1 {
	margin-bottom: 32px;
}
.history-head p {
	font-size: 24px;
	line-height: 1.4;
	font-weight: 500;
}

.history-content {
	text-align: center;
	padding: 90px 0 312px;
	width: 65%;
	margin: 0 auto;
}
.history-col h2 {
	margin-bottom: 5px;
}
.history-col h4 {
	font-size: 26px;
	margin-bottom: 27px;
}
.history-col p {
	letter-spacing: -0.2px;
}

.pattern-div {
	display: block;
	margin: 0 auto;
	width: 24px;
	position: absolute;
	left: 0;
	right: 0;
	top: 100%;
	margin-top: 57px;
}
.history-col {
	margin-bottom: 154px;
	position: relative;
	transition: all 0.5s ease-in-out;
	opacity: 0;
}
.history-col.show-text {
	opacity: 1;
}
.history-col:first-child {
	margin-bottom: 161px;
}
.history-col:first-child .pattern-div {
	margin-top: 45px;
}
.history-col:last-child {
	padding-top: 10px;
}
.history-col:nth-last-child(2) .pattern-div {
	margin-top: 50px;
}
.circle-div {
	display: block;
	width: 24px;
	height: 24px;
	background: none;
	border: 0;
	box-sizing: border-box;
	box-shadow: inset 0 0 0 2px #f45e61;
	color: #f45e61;
	font-size: inherit;
	font-weight: 700;
	position: relative;
	border-radius: 100%;
	box-shadow: none;
	opacity: 0;
}
.circle-div:after,
span.circle-div:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	border-radius: 100%;
}
.circle-div:before {
	border: 5px solid transparent;
}
.circle-div:after {
	border: 0 solid transparent;
}

.show-section .circle-div:before {
	border-top-color: #02aca0;
	border-right-color: #02aca0;
	border-bottom-color: #02aca0;
	border-left-color: #02aca0;
	transition: border-top-color 0.15s linear,
		border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s,
		border-left-color 0.2s linear 0.2s;
	-webkit-transition: border-top-color 0.15s linear,
		border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s,
		border-left-color 0.2s linear 0.2s;
	-moz-transition: border-top-color 0.15s linear,
		border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s,
		border-left-color 0.2s linear 0.2s;
	-ms-transition: border-top-color 0.15s linear,
		border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s,
		border-left-color 0.2s linear 0.2s;
	-o-transition: border-top-color 0.15s linear,
		border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s,
		border-left-color 0.2s linear 0.2s;
}

.show-section .circle-div:after {
	border-top: 5px solid #02aca0;
	border-left-width: 5px;
	border-right-width: 5px;
	-webkit-transform: rotate(400deg);
	transform: rotate(400deg);
	transition: border-left-width 0s linear 0.35s,
		-webkit-transform 0.4s linear 0s;
	transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s;
	transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s,
		-webkit-transform 0.4s linear 0s;
	-moz-transform: rotate(400deg);
	-ms-transform: rotate(400deg);
	-o-transform: rotate(400deg);
}

.show-section .circle-div {
	color: #02aca0;
	opacity: 1;
}

.vertical-line-div {
	display: block;
	margin: 0 auto;
	background: #02aca0;
	width: 5px;
	height: 0px;
	margin-top: -1px;
	margin-bottom: -1px;
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	opacity: 0;
}
.show-section .vertical-line-div {
	height: 55px;
	opacity: 1;
}
.horizontal-line-div {
	display: block;
	margin: 0 auto;
	background: #02aca0;
	height: 5px;
	width: 0px;
	border-radius: 30px;
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	opacity: 0;
}
.show-section .horizontal-line-div {
	width: 20px;
	opacity: 1;
}

.show-section .circle-div + .vertical-line-div {
	transition-delay: 0.5s;
}
.show-section .vertical-line-div + .horizontal-line-div {
	transition-delay: 1s;
}
.show-section .horizontal-line-div + .vertical-line-div {
	transition-delay: 0.5s;
}
.vertical-line-div + .circle-div {
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition-delay: 1s;
}
.show-section .vertical-line-div + .circle-div:before {
	transition: border-top-color 0.15s linear 1s,
		border-right-color 0.15s linear 1.1s, border-bottom-color 0.15s linear 1.2s,
		border-left-color 0.2s linear 1.3s;
	-webkit-transition: border-top-color 0.15s linear 1s,
		border-right-color 0.15s linear 1.1s, border-bottom-color 0.15s linear 1.2s,
		border-left-color 0.2s linear 1.3s;
	-moz-transition: border-top-color 0.15s linear 1s,
		border-right-color 0.15s linear 1.1s, border-bottom-color 0.15s linear 1.2s,
		border-left-color 0.2s linear 1.3s;
	-ms-transition: border-top-color 0.15s linear 1s,
		border-right-color 0.15s linear 1.1s, border-bottom-color 0.15s linear 1.2s,
		border-left-color 0.2s linear 1.3s;
	-o-transition: border-top-color 0.15s linear 1s,
		border-right-color 0.15s linear 1.1s, border-bottom-color 0.15s linear 1.2s,
		border-left-color 0.2s linear 1.3s;
}
.show-section .vertical-line-div + .circle-div:after {
	transition: border-left-width 0s linear 1.35s, transform 0.4s linear 1s;
	transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s;
	-webkit-transition: border-left-width 0s linear 1.35s,
		transform 0.4s linear 1s;
	-moz-transition: border-left-width 0s linear 1.35s, transform 0.4s linear 1s;
	-ms-transition: border-left-width 0s linear 1.35s, transform 0.4s linear 1s;
	-o-transition: border-left-width 0s linear 1.35s, transform 0.4s linear 1s;
}

/***** HistoryPage style ends here *****/

/*****HCP page style starts here *****/
.hcp-page {
	padding-top: 235px;
}
/*.hcp-page {padding-top: 116px;background: rgb(223,223,223);background: -moz-linear-gradient(180deg, rgba(255,253,253,1) 0%, rgba(223,223,223,1) 100%);background: -webkit-linear-gradient(180deg, rgba(255,253,253,1) 0%, rgba(223,223,223,1) 100%);background: linear-gradient(180deg, rgba(255,253,253,1) 0%, rgba(223,223,223,1) 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dfdfdf",endColorstr="#fffdfd",GradientType=1);}*/

/***** HCP page style ends here *****/

.inner-page-content {
	padding: 80px 0;
}
.inner-page-content p + h3 {
	margin-top: 40px;
}
.inner-page-content p + h2 {
	margin-top: 70px;
}
.inner-page-content h2 {
	margin-bottom: 10px;
	letter-spacing: 1px;
}
.inner-page-content h3 {
	margin-bottom: 10px;
}
.inner-page-content p:last-child {
	margin-bottom: 0;
}
.inner-page-content p a {
	color: #02aca0;
}
.inner-page-content p a:hover,
.inner-page-content p a:focus {
	color: #000;
}
.inner-blue-box .col-img img {
	max-width: 86px;
}

/***** OrderPage style starts here *****/
.inner-banner h1 span {
	display: block;
}
.order-page-banner {
	padding-top: 156px;
	padding-bottom: 45px;
}
.order-page-banner h1 {
	margin-bottom: 20px;
}
.order-page-banner p {
	width: 55%;
}
.blue-step-box {
	background: rgb(0, 172, 159);
	background: linear-gradient(
		0deg,
		rgba(0, 172, 159, 1) 0%,
		rgba(0, 150, 214, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00ac9f",endColorstr="#0096d6",GradientType=1);
	padding: 60px 0 74px;
}
.blue-step-box .container {
	max-width: 900px;
}
.box-content {
	text-align: center;
	color: #fff;
	padding: 0 10px;
}
.box-image {
	display: inline-block;
	vertical-align: middle;
	width: 117px;
	height: 117px;
	margin: 0 auto 36px;
	background: #ffb405;
	border-radius: 100%;
	line-height: 117px;
}
.box-content h4 {
	color: #fff;
	margin-bottom: 45px;
	font-size: 22px;
}
.box-content p {
	letter-spacing: 0;
	line-height: 1.25;
}
.subscription-section {
	padding: 98px 0;
	background: rgb(223, 223, 223);
	background: linear-gradient(
		-90deg,
		rgba(223, 223, 223, 1) 0%,
		rgba(255, 253, 253, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dfdfdf",endColorstr="#fffdfd",GradientType=1);
}
.subscription-col {
	text-align: center;
	margin-bottom: 50px;
}
.subscription-col h4 {
	position: relative;
	padding-bottom: 21px;
	margin-bottom: 35px;
}
.subscription-col h4:after {
	content: '';
	height: 5px;
	background: #02aca0;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 10px;
}
.subscription-col h2 span {
	display: block;
	font-size: 24px;
	margin-top: 6px;
}
.subscription-col p span {
	display: block;
	margin-top: 8px;
}
.subscription-button input[type='radio'] {
	opacity: 0;
	position: absolute;
}
.subscription-button {
	position: relative;
	margin-top: 30px;
}
.subscription-button .btn:hover,
.subscription-button .btn:focus {
	background: #00baff;
}
.next-step {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 88px 0 82px;
}
.arrow-btn:last-child {
	margin-left: auto;
}
.arrow-btn {
	position: relative;
}
.arrow-btn:after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	right: 11px;
	background: url('../images/right-ic.svg');
	width: 24px;
	height: 24px;
	background-repeat: no-repeat;
	background-position: center center;
	margin: auto;
	background-size: 12px;
}
.arrow-btn.prev-btn:after {
	right: auto;
	left: 11px;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}
.disable-btn {
	background: #dcdbdb;
	pointer-events: none;
	box-shadow: none !important;
}
.step-gradient-overlay {
	background: rgb(223, 223, 223);
	background: linear-gradient(
		180deg,
		rgba(255, 253, 253, 1) 0%,
		rgba(223, 223, 223, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dfdfdf",endColorstr="#fffdfd",GradientType=1);
}
.step-chart-sec {
	padding: 184px 0 0;
}
.step-chart-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	overflow: hidden;
	padding-bottom: 96px;
}
.step-chart-wrapper:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
	background: #02aca0;
	height: 5px;
	border-radius: 10px;
	width: 48%;
}
.step-chart-wrapper h4 {
	font-weight: 300;
	margin-bottom: 11px;
}
.step-col {
	text-align: center;
	width: 25%;
	position: relative;
}
.step-chart-wrapper p {
	letter-spacing: 0;
	font-weight: 500;
}
.circle-step {
	display: block;
	width: 46px;
	height: 46px;
	border: 5px solid #02aca0;
	background: #fff;
	border-radius: 100%;
	margin: 22px auto 0;
	position: relative;
	z-index: 1;
}
.circle-step-line {
	position: absolute;
	right: 50%;
	bottom: 21px;
	background: #02aca0;
	height: 5px;
	width: 100%;
}
.step-done .circle-step {
	position: relative;
	border: none;
}
.step-done .circle-step:after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background: url(../images/check-ic.svg) no-repeat center center;
	background-size: 40px;
	z-index: 1;
	border-radius: 100%;
	border: 5px solid #02aca0;
}
.step-disable .circle-step {
	border-color: #dcdbdb;
}
.step-disable .circle-step-line {
	background: #dcdbdb;
}
.div-content {
	padding: 24px 12px 0 0;
}
.div-content:empty {
	padding: 0;
}
.div-content p {
	margin: 0;
	font-size: 18px;
	font-weight: 400;
	line-height: 1.45;
}
.disable-CampAss {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 99;
}
.disable-div {
	pointer-events: none;
	opacity: 0.5;
}
.disable-div .custom-radio {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}
.disable-div .custom-radio label {
	border-color: #dcdbdb;
	color: #dcdbdb;
}
.warning-div.warning-div-para {
	padding: 6px 0 0;
}
.three-text-box .text-danger {
	display: none;
}
.step-col:first-child .circle-step-line {
	display: none;
}
.step-content-row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 44px;
}
.step-content-row:last-child {
	margin-bottom: 42px;
}
.step-chart-content {
	padding: 151px 0 170px;
}
.step-content-left {
	width: 50%;
	padding-right: 14px;
}
.step-content-right {
	width: 50%;
	padding-left: 15px;
}
.step-content-left p:only-child {
	margin: 0;
}
.custom-radio {
	margin-right: 30px;
	display: inline-block;
	vertical-align: top;
}
.custom-radio:last-child {
	margin-right: 0;
}
.custom-radio label {
	display: inline-block;
	vertical-align: middle;
	border: 2px solid #50e3c2;
	border-radius: 30px;
	min-width: 140px;
	text-align: center;
	color: #4a4a4a;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 18px;
	padding: 5px 15px 3px;
	cursor: pointer;
	letter-spacing: 0.5px;
}
.custom-radio .red-border {
	border-color: #da4259;
}
.custom-radio input[type='radio']:checked + label {
	background: #42da8c;
	border-color: #42da8c;
	color: #fff;
}
.custom-radio input[type='radio']:checked + .red-border {
	background: #da4259;
	border-color: #da4259;
}
textarea,
input[type='text'],
input[type='tel'],
input[type='email'],
input[type='password'],
input[type='date'],
input[type='search'],
textarea,
select {
	border-bottom: 1px solid #000000;
	width: 100%;
	font-size: 20px;
	color: #9b9b9b;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	line-height: 22px;
	padding: 7px 0 7px;
	letter-spacing: 0;
	background: transparent;
}
textarea,
input[type='text'],
input[type='tel'],
input[type='email'],
input[type='password'],
input[type='date'],
input[type='search'],
textarea,
select {
	padding: 9px 0 9px;
}
select {
	cursor: pointer;
	background-image: url('../images/down-triangle.svg');
	background-repeat: no-repeat;
	background-position: top 22px right 16px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0;
}
select::-ms-expand {
	display: none;
}
select:focus {
	color: #000;
}
input::-moz-placeholder {
	opacity: 1 !important;
}
input:-moz-placeholder {
	opacity: 1 !important;
}
input::-webkit-input-placeholder {
	color: #9b9b9b !important;
	opacity: 1 !important;
}
input:-ms-input-placeholder {
	opacity: 1 !important;
}
textarea::-moz-placeholder {
	opacity: 1 !important;
}
textarea:-moz-placeholder {
	opacity: 1 !important;
}
textarea::-webkit-input-placeholder {
	color: #9b9b9b !important;
	opacity: 1 !important;
}
textarea:-ms-input-placeholder {
	opacity: 1 !important;
}
.step-content-row.step-content-row-field {
	margin-bottom: 52px;
}
.step-content-left p {
	margin-bottom: 11px;
}
.step-content-left select {
	margin-bottom: 10px;
}
/*.form-field-wrapper {padding: 26px 0 0px;}*/
.form-field {
	margin-bottom: 19px;
	width: 100%;
	position: relative;
}
.form-field:last-child {
	margin-bottom: 0;
}
.three-text-box {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 34px;
	position: static;
}
.three-text-box .form-field {
	width: 30%;
	margin-right: 2%;
	margin-bottom: 0;
}
.three-text-box .form-field:nth-child(1) {
	width: 17%;
}
.three-text-box .form-field:last-child {
	margin-right: 0;
}
.three-text-box .form-field:nth-child(2) {
	width: 27%;
}
.three-text-box .form-field:nth-child(3) {
	width: 14%;
}
.three-text-box:last-child {
	margin-bottom: 0;
}
.warning-div {
	padding: 0;
}
.warning-div img {
	margin-bottom: 10px;
}
.step-content-right p {
	font-size: 18px;
}
.step-content-right p span {
	color: #da4259;
	font-weight: 500;
}
.step-inner-content {
	margin-top: 20px;
}
.three-text-box {
	position: relative;
}
.form-field:after,
.three-text-box:after {
	content: '';
	position: absolute;
	bottom: 13px;
	right: 0;
	background-image: url(../images/answer-tick.svg);
	background-repeat: no-repeat;
	background-position: center right;
	width: 30px;
	height: 30px;
	background-size: 27px;
	opacity: 0;
}
.form-field.value-exist:after,
.three-text-box.value-exist:after {
	opacity: 1;
}
.value-exist input,
.form-field.value-exist {
	color: #000;
}
.invalid-serial:after {
	background-image: url('../images/red-close.svg');
}
.invalid-serial:after {
	opacity: 1;
}
.order-confirmation {
	padding-bottom: 170px;
}
.order-info-right {
	background: #fff;
	width: 83%;
	margin-left: auto;
	position: relative;
	padding: 30px 36px 35px;
}
.order-info-right:after,
.order-info-right:before {
	content: '';
	position: absolute;
	left: 0;
	width: 100%;
	border-radius: 10px;
	background: #02aca0;
	height: 3px;
}
.order-info-right:after {
	bottom: 0;
}
.order-info-right:before {
	top: 0;
}
.order-info-right p {
	margin-bottom: 22px;
}
.order-info-right p:nth-last-child(2) {
	margin-top: 63px;
}
.order-info-right p:last-child {
	margin-bottom: 0;
	font-weight: 400;
}
.order-info-right p span {
	margin-left: auto;
	float: right;
}
.order-confirmation .next-step {
	margin-top: 57px;
}
.order-info-left p {
	font-weight: 400;
	margin-bottom: 7px;
	line-height: 1.45;
}
.term-agree {
	margin-top: 37px;
}
.term-agree p {
	font-weight: 300;
	margin-bottom: 21px;
}
.term-agree p label {
	width: 100%;
	position: relative;
	cursor: pointer;
}
.term-agree p label:before,
.term-agree p label:after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	width: 30px;
	height: 30px;
	border: 2px solid #50e3c2;
	border-radius: 100%;
	-webkit-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}
.term-agree p label:after {
	background: url('../images/green-check-ic.svg') no-repeat center center;
	transform: scale(0);
	background-size: 30px;
}
.term-agree p input[type='checkbox']:checked + label:after {
	transform: scale(1);
}
.captcha-col {
	margin: 60px 0 53px;
}
.step-content-row select + .form-field {
	margin-top: 25px;
}
.discount-code input[type='submit'] {
	margin-top: 34px;
}
.three-text-box input + .text-danger {
	left: 0;
}
.order-confirmation {
	padding: 153px 0 171px;
}
.discount-code .txt {
	margin-top: 17px;
}
.discount-code .btn {
	margin-top: 31px;
}
.order-info-right p:first-child {
	color: #02aca0;
	font-weight: 500;
}
.popup-msg-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.4);
	z-index: 11;
}
.popup-msg-wrapper .container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.popup-msg {
	background: #fff;
	width: 486px;
	text-align: center;
	position: relative;
	padding: 50px 15px 60px;
}
.popup-msg:after,
.popup-msg:before {
	content: '';
	position: absolute;
	left: 0;
	width: 100%;
	border-radius: 10px;
	background: #02aca0;
	height: 3px;
}
.popup-msg-error:after,
.popup-msg-error:before {
	content: '';
	position: absolute;
	left: 0;
	width: 100%;
	border-radius: 10px;
	background: #da4259;
	height: 3px;
}
.popup-msg:after {
	bottom: 0;
}
.popup-msg:before {
	top: 0;
}
.popup-msg h5 {
	color: #02aca0;
	font-weight: 400;
	margin-bottom: 53px;
	text-transform: none;
	letter-spacing: 0;
	font-size: 20px;
}
.popup-msg img + p {
	margin-top: 42px;
}
.popup-msg .btn,
.btn.small {
	font-size: 16px;
	font-weight: 700;
	min-width: 140px;
	padding: 5px 10px;
	line-height: 18px;
	margin-top: 28px;
}
.popup-msg .btn:hover,
.popup-msg .btn:focus,
.arrow-btn:hover,
.arrow-btn:focus,
.btn.small:hover,
.btn.small:focus,
.discount-code .btn:hover,
.discount-code .btn:focus {
	background: #00baff;
}
.vat-form {
	padding: 27px 0 13px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.vat-form .half_width {
	margin-left: auto;
	width: 47%;
}
.vat-form .three-text-box .form-field:nth-child(1) {
	width: 11%;
}
.vat-form .three-text-box .form-field:nth-child(2) {
	width: 30%;
}
.vat-form .three-text-box .form-field:nth-child(3) {
	width: 18%;
}
.vat-form .three-text-box .form-field {
	margin-right: 6%;
}
.vat-form .three-text-box .form-field:last-child {
	margin-right: 0;
}
.step-content-left input + .text-danger {
	position: absolute;
	top: 100%;
	font-size: 16px;
	font-weight: 400;
}
.subscription-button input[type='radio']:checked + label {
	background: #00baff;
	box-shadow: none;
}
.step-col .font-weight-bold {
	font-weight: 500 !important;
}
.div-content.bottom-para {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: calc(100% - 30px);
}
.step-content-right .div-content.bottom-para .bottom-para-p {
	margin-top: auto;
}
.arrow-complete:after {
	display: none;
}
.invalid-serial input {
	border-color: #dc3545;
}
.step-box-heading h2 {
	text-align: center;
	color: #fff;
	margin-bottom: 50px;
}
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.load-ic {
	-webkit-animation: spin 2s linear infinite;
	animation: spin 1.5s linear infinite;
}
.three-text-box .form-field:only-child {
	width: 100% !important;
}
.div-content.div-content-other {
	padding-top: 46px;
	height: calc(100% - 0px);
}
.div-content.div-content-other .bottom-para-p {
	margin-bottom: -15px;
}
.custom-radio input[type='radio'] {
	opacity: 0;
}
/***** OrderPage style ends here *****/

/****** extra pages style starts here ******/
.home-article-sec {
	padding: 137px 0 40px;
}
.home-article-main {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 30px;
}
.article-img {
	width: 301px;
	height: 301px;
	border-radius: 100%;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	overflow: hidden;
}
.article-content {
	width: calc(100% - 301px);
	padding-left: 64px;
	padding-right: 50px;
	padding-bottom: 39px;
}
.border-link {
	display: inline-block;
	vertical-align: middle;
	border-bottom: 1px solid;
	color: #02aca0;
	line-height: 0.8;
}
.border-link:hover,
.border-link:focus {
	border-bottom-color: transparent;
}
.article-img img {
	display: block;
	border-radius: 100%;
}
.article-content h2 {
	margin-bottom: 18px;
}
.article-content .border-link {
	margin-top: 17px;
}
.home-article-main:nth-child(even) .article-content {
	order: -1;
	padding-left: 0;
	padding-right: 110px;
}
.article-btn {
	text-align: center;
	margin-top: 99px;
}
.content-inner {
	padding-top: 126px;
}
.content-inner:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 5px;
	background: #02aca0;
}
.review-page-content {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: -38px;
}
.review-content-small img {
	display: block;
	border-radius: 100%;
	margin: 0 auto 32px;
}
.review-content-small {
	width: 32%;
	position: relative;
	padding: 33px 3px 43px;
}
.review-content-large {
	width: 68%;
	padding-left: 25px;
	margin-top: 62px;
}
.review-content-small:before, .review-content-head:before, .review-content-info:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 5px;
	background: #02aca0;
	border-radius: 10px;
}
.review-page {
	padding: 107px 0 0;
}
.review-content-small h2 {
	margin-bottom: 2px;
}
.review-content-small .border-link {
	margin-top: 7px;
}
.review-page-content:nth-child(even) .review-content-large {
	width: 65%;
	order: -1;
	padding-left: 0;
	padding-right: 23px;
}
.review-content-small:after,
.review-content-head:after,
.review-content-info:after {
	top: auto;
	bottom: 0;
}
.review-content-head {
	position: relative;
	padding: 20px 0 26px;
}
.review-content-head h2 {
	font-size: 48px;
	font-weight: 400;
	letter-spacing: -0.3px;
	line-height: 1.2;
	margin-bottom: 15px;
	color: #000000;
}
.review-content-large p {
	margin-bottom: 0;
}
.review-content-large p span {
	font-size: 20px;
	letter-spacing: -0.13px;
	font-weight: 300;
}
.review-content-info p {
	font-size: 24px;
	font-weight: 400;
	letter-spacing: -0.16px;
	line-height: 1.22;
	margin-bottom: 10px;
}
.review-content-info {
	position: relative;
	margin-top: 28px;
	padding: 30px 0;
	max-width: 487px;
}
.review-content-info p:last-child {
	margin-bottom: 0;
}
.review-content-info:first-child {
	margin-top: 0;
}
.review-page-content:nth-child(even) {
	padding-right: 78px;
}
.review-page-content:first-child .review-content-large {
	margin-top: 0;
}
.review-page-content:nth-child(even)
	.review-content-large
	.review-content-info {
	max-width: 573px;
}
.review-page-content:nth-child(even) .review-content-small {
	width: 35%;
}
.review-page-content:nth-child(even)
	.review-content-large
	.review-content-head {
	margin-left: auto;
	max-width: 401px;
	margin-top: 28px;
	padding-left: 2px;
}
.review-page-content:last-child {
	margin-bottom: 0;
}
.review-page + .content-sec {
	margin-bottom: 45px;
}
.review-page + .content-sec .content-inner:before {
	display: none;
}
.review-page + .content-sec .content-inner {
	padding-top: 117px;
	border-top: 5px solid #02aca0;
}

.review-article-main {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 104px 0px 0 0px;
	align-items: flex-start;
}
.article-left {
	width: 68.3%;
	padding-right: 110px;
}
.article-left h2 {
	margin-bottom: 29px;
}
.article-left p strong {
	font-weight: 500;
	display: block;
	letter-spacing: -0.13px;
	line-height: 1.4;
	margin-bottom: 29px;
}
.article-right {
	width: 31.7%;
}
.article-right-inner {
	position: relative;
	padding: 33px 0 20px;
	margin-bottom: 53px;
}
.article-img-col {
	display: block;
}
.article-img-col img {
	display: block;
	margin: 0 auto 45px;
	border-radius: 100%;
}
.article-left p + h3 {
	margin-top: 25px;
}
.review-article-page {
	padding-bottom: 40px;
}
.article-right-inner:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 5px;
	background: #02aca0;
	border-radius: 10px;
}
.article-right-inner:after {
	top: auto;
	bottom: 0;
}
.article-img-col h5 {
	padding: 0 5px;
	text-transform: none;
	font-weight: 500;
	font-size: 20px;
}
.article-img-col h5 span {
	display: block;
	margin-top: 19px;
}
.review-img {
	display: block;
	width: 312px;
	height: 312px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	border-radius: 100%;
	margin-bottom: 30px;
}
.article-img-col .review-img {
	margin-bottom: 48px;
}

.site-navigation ul ul {
	position: absolute;
	top: 100%;
	left: 50%;
	background: #fff;
	min-width: 1px;
	transform: translateX(-50%);
	border-top: 1px solid #000000;
	width: 100%;
}
.site-navigation ul li {
	position: relative;
}
.site-navigation ul ul li {
	padding: 0;
	line-height: 1;
	display: block;
	width: 100%;
	border-bottom: 1px solid #D9D9D9;
}
.site-navigation ul ul li a {
	color: #fff;
	text-align: center;
	padding: 14px 15px;
	display: block;
	line-height: 1;
}
.site-navigation ul li:hover a {
    color: #000000;
}

.site-navigation ul li a, .site-navigation ul li span {
    position: relative;
    cursor: pointer;
}

.site-navigation ul li a:before, .site-navigation ul li span:before {content: "";position: absolute;top: -11px;left: 0;right: 0;bottom: 0;background: #fff;z-index: -1;opacity: 0;transition: all 0.5s ease-in-out;}

.site-navigation ul li:hover a:before,
.site-navigation ul li:focus a,
.site-navigation ul li:hover span:before,
.site-navigation ul li:focus span:before {
    opacity: 1;
}
.site-navigation ul li li a:before, .site-navigation ul li li span:before { display: none; }
.header-top-bar { background: #02aca0; color: #fff; margin: -30px -18px 0; text-align: center; padding: 8px 0 5px; margin-bottom: 30px; } 
.header-top-bar p { margin: 0; font-size: 16px; display: inline-block; vertical-align: top; line-height: 1; }
.header-top-bar p a { display: inline-block; vertical-align: top; margin-left: 16px; line-height: 1; font-size: 16px; margin-top: -1px; }
.header-top-bar > div { line-height: 1; }
.header-top-bar p a:hover, .header-top-bar p a:focus { color: #fff; text-decoration: none; }
@media (min-width: 768px) {
	.site-navigation ul ul {
		display: none;
	}
	.site-navigation ul li:hover > ul {
		display: block;
	}
}
.site-navigation ul ul li a:hover,
.site-navigation ul ul li a:focus,
.site-navigation ul ul > li > .active {
	color: #02aca0;
	background: #fff;
}

.popup__wrapper {
	position: relative;
}

.video-btn {
	display: inline-block;
	vertical-align: middle;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%);
	margin-top: -35px;
}
/***** Responsive style starts here *****/

@media (max-width: 1500px) {
	.site-navigation ul li a, .site-navigation ul li span {
		padding: 0 40px 11px;
	}
}

@media (max-width: 1400px) {
	.site-navigation ul li a, .site-navigation ul li span {
		padding: 0 25px 11px;
	}
	.lang-label {
		min-width: 1px;
		width: 100%;
	}
}
@media (max-width: 1199px) {
	.btn {
		font-size: 22px;
		line-height: 24px;
		padding: 14px 10px 12px;
		letter-spacing: 1px;
	}
	h2 {
		font-size: 35px;
		margin-bottom: 15px;
	}
	h3 {
		font-size: 26px;
		margin-bottom: 15px;
		line-height: 1;
	}
	h5 {
		letter-spacing: 1px;
	}
	p,
	.blue-content-box.inner-blue-box p,
	body {
		font-size: 18px;
	}
	/***** Header style starts here *****/
	.site-navigation {
		padding-left: 20px;
	}
	.site-navigation ul li a,
	.site-navigation ul li span {
		padding: 0 12px 11px;
	}
	/***** Header style ends here *****/
	/***** Home style starts here *****/
	.banner-wrap {
		padding-top: 90px;
	}
	.banner-left {
		min-height: 500px;
		padding-top: 50px;
	}
	.main-banner h1 {
		font-size: 60px;
	}
	.main-banner p,
	.inner-banner p,
	.history-head p {
		font-size: 22px;
	}
	.main-banner .btn {
		margin-top: 30px;
	}

	.banner-right img {
		max-width: 300px;
		margin-left: auto;
		margin-right: 0;
	}

	.blue-content-box {
		padding: 80px 0;
	}
	.blue-content-box h2 {
		margin-bottom: 15px;
	}
	/*
	.blue-content-box h2 span {
		font-size: 50px;
		letter-spacing: 6px;
	}
*/
	/*
	.blue-content-box p {
		font-size: 28px;
		width: 60%;
	}
*/

	.service-sec {
		padding: 50px 0 39px;
	}

	.content-left {
		padding: 99px 0 27px;
	}
	.single-ic-sec ol {
		margin-top: 50px;
	}
	.single-ic-sec .image-right-wrap:after {
		transform: skewX(-41.4deg);
	}

	.content-sec {
		padding: 80px 0 40px;
	}
	.content-sec .col-12 {
		padding: 0 150px;
	}
	.content-inner {
		padding-bottom: 100px;
	}
	.content-sec h2 {
		margin-bottom: 20px;
	}
	.content-inner > .btn {
		margin-top: 40px;
	}

	.two-col-section {
		padding-bottom: 140px;
	}
	.two-col-section h2 {
		margin-bottom: 40px;
	}
	.two-col-section h5 + h3 {
		margin-top: 20px;
	}
	.two-col-section .download-link {
		margin-top: 20px;
	}
	.right-col .download-link {
		margin-top: 0;
	}
	.right-col .download-link + h5 {
		margin-top: 30px;
	}
	/***** Home style ends here *****/
	/***** Footer style starts here *****/
	.footer-main {
		padding-top: 50px;
	}
	.footer-top,
	.footer-bottom {
		padding: 0 18px;
	}
	.footer-copyright {
		padding: 7px 32px 6px;
	}
	.footer-main ul li a {
		font-size: 18px;
	}
	.footer-main ul li {
		line-height: 1;
		margin-bottom: 15px;
	}
	.footer-bottom {
		padding-top: 40px;
		padding-bottom: 45px;
	}
	/***** Footer style ends here *****/

	h1 {
		font-size: 50px;
	}
	.inner-banner .container {
		min-height: 300px;
	}
	.inner-banner h1 {
		margin-bottom: 20px;
	}

	.accordian-title {
		padding: 23px 35px 23px;
	}
	.accordian-title h4 {
		font-size: 22px;
	}

	.history-head {
		padding: 90px 0 115px;
		width: 65%;
	}
	.history-content {
		padding-bottom: 50px;
	}

	.info-col h3 {
		margin-bottom: 20px;
	}
	.info-col {
		padding-left: 50px;
	}
	.support-sec {
		padding: 80px 0 30px;
	}
	.sub-info-col {
		padding-top: 80px;
	}
	.info-col h4 {
		font-size: 18px;
	}
	.info-col .download-link + h4 {
		margin-top: 25px;
	}
	.info-col p {
		margin-bottom: 30px;
	}
	.info-col a {
		margin-bottom: 10px;
	}
	.content-wrap {
		padding-bottom: 100px;
	}
	.content-wrap:after {
		width: 80%;
	}
	.support-sec + .support-sec {
		padding-top: 40px;
	}
	.info-col .btn + h4 {
		margin-top: 50px;
	}
	.support-sec + .support-sec .content-wrap {
		padding-bottom: 200px;
	}

	.custom-scrollbar {
		max-height: 327px;
	}

	.inner-page-content p + h2 {
		margin-top: 50px;
	}
	.inner-page-content p + h3 {
		margin-top: 30px;
	}

	.accordian-content {
		padding: 66px 35px 0px;
		margin-top: -66px;
	}
	.accordian-title.active + .accordian-content {
		padding: 112px 35px 35px;
	}
	/* order page */
	.next-step {
		padding: 60px 0 50px;
	}
	.subscription-button .btn {
		width: 90%;
		min-width: 1px;
	}
	.box-content h4 {
		margin-bottom: 25px;
	}
	.step-chart-content {
		padding: 150px 0 110px;
	}
	.order-confirmation {
		padding: 110px 0 110px;
	}
	.captcha-col {
		margin: 40px 0;
	}
	.popup-msg h5 {
		margin-bottom: 30px;
	}
	.popup-msg img + p {
		margin-top: 25px;
	}
	.popup-msg .btn,
	.btn.small {
		margin-top: 20px;
	}
	.popup-msg {
		padding: 40px 15px;
	}
	/* order page */
	.home-article-sec {
		padding: 110px 0 40px;
	}
	.review-content-head h2 {
		font-size: 40px;
	}
	.review-content-info p {
		font-size: 20px;
		line-height: 1.4;
	}
	.review-img {
		width: 290px;
		height: 290px;
	}
	.review-page-content:nth-child(even) {
		padding-right: 50px;
	}
	.article-left h2 {
		margin-bottom: 20px;
	}
	.article-left p strong {
		margin-bottom: 20px;
	}
	.article-left p + h3 {
		margin-top: 20px;
		line-height: 1.4;
	}
	.review-article-page {
		padding-bottom: 20px;
	}
}

@media (max-width: 1024px) {
	* {
		-webkit-appearance: none;
		border-radius: 0px;
		-webkit-border-radius: 0px;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}
@media (max-width: 991px) {
	.btn {
		font-size: 20px;
		line-height: 22px;
		padding: 13px 10px 12px;
		letter-spacing: 1px;
		min-width: 250px;
		box-shadow: 0px 6px 20px -4px rgba(2, 172, 160, 1);
	}
	button,
	html input[type='button'],
	input[type='submit'] {
		min-width: 250px;
		box-shadow: 0px 6px 20px -4px rgba(2, 172, 160, 1);
		font-size: 20px;
		line-height: 22px;
		padding: 13px 10px 12px;
		letter-spacing: 1px;
	}
	h1 {
		font-size: 44px;
	}
	h2 {
		font-size: 32px;
		letter-spacing: 0.5px;
	}
	h3 {
		font-size: 22px;
		margin-bottom: 10px;
	}
	p,
	h5,
	.blue-content-box.inner-blue-box p,
	body {
		font-size: 16px;
	}
	li {
		font-size: 16px;
	}

	.header-main {
		padding: 30px 0;
	}
	.logo {
		width: 110px;
	}
	
	.header-navigation {
		width: calc(100% - 110px);
	}
	.site-navigation {
		padding-left: 20px;
		width: 82%;
	}
	.site-navigation ul li {
		line-height: 1;
	}
	.lang-dropdown {
		width: 18%;
	}
	.lang-label {
		font-size: 16px;
	}
	.lang-list li a {
		padding: 10px 38px 8px;
	}
	.lang-label label {
		font-size: 16px;
	}

	.banner-wrap {
		padding-top: 20px;
	}
	.main-banner h1 {
		font-size: 46px;
		margin-bottom: 10px;
	}
	.main-banner p,
	.inner-banner p,
	.history-head p {
		font-size: 20px;
	}
	.banner-left {
		padding-top: 80px;
	}
	.banner-right img {
		max-width: 250px;
	}
	.main-banner .btn {
		margin-top: 15px;
	}

	.content-left {
		padding: 60px 0 27px;
	}
	.image-right {
		width: 250px;
		height: 250px;
		line-height: 250px;
	}
	.image-right img {
		max-width: 150px;
	}

	/*
	.blue-content-box h2 span {
		font-size: 46px;
		letter-spacing: 3px;
	}
*/
	/*
	.blue-content-box p {
		width: 70%;
		font-size: 22px;
	}
*/

	.single-ic-sec .image-right-wrap:after {
		left: 125px;
		transform: skewX(-40.1deg);
		-webkit-transform: skewX(-40.1deg);
		-moz-transform: skewX(-40.1deg);
		-ms-transform: skewX(-40.1deg);
		-o-transform: skewX(-40.1deg);
	}
	.icon-col {
		margin: 30px auto;
	}
	.service-sec {
		padding: 20px 0 19px;
	}
	.col-img {
		margin-bottom: 25px;
	}

	.content-sec {
		padding: 60px 0 40px;
	}
	.content-sec .col-12 {
		padding: 0 50px;
	}
	.content-inner {
		padding-bottom: 50px;
		border-bottom-width: 3px;
	}
	.content-inner > .btn {
		margin-top: 30px;
	}

	.two-col-section {
		padding-top: 20px;
		padding-bottom: 100px;
	}
	.two-col-section h5 + h3 {
		margin-top: 15px;
	}
	.two-col-section .download-link {
		margin-top: 10px;
	}
	.right-content {
		padding-left: 30px;
	}
	.right-col .download-link {
		margin-top: 0;
	}
	.right-col .download-link + h5 {
		margin-top: 20px;
	}

	.footer-col {
		min-width: 17%;
	}
	.footer-col:nth-child(2) {
		min-width: 25%;
	}
	.footer-bottom p {
		width: 100%;
	}
	.footer-main ul li {
		margin-bottom: 10px;
	}
	.footer-main ul li a {
		font-size: 16px;
	}
	.footer-bottom {
		padding-top: 30px;
		padding-bottom: 35px;
	}

	.faq-main {
		padding: 50px 0 50px;
	}
	.accordian-col {
		width: 100%;
	}
	.accordian-title {
		padding: 20px 30px 20px;
	}
	.accordian-title h4 {
		font-size: 20px;
	}
	.accordian-content {
		padding: 60px 35px 0px;
		margin-top: -60px;
	}
	.accordian-title.active + .accordian-content {
		padding: 106px 35px 35px;
	}
	.accordian-title:before,
	.accordian-title:after {
		right: 30px;
	}

	.inner-banner p {
		width: 75%;
	}
	.inner-banner .container {
		min-height: 250px;
	}

	.history-head {
		width: 84%;
		padding: 50px 0 60px;
	}
	.history-content {
		width: 84%;
		padding-top: 60px;
		padding-bottom: 90px;
	}
	.history-col {
		margin-bottom: 130px;
	}
	.history-col:last-child {
		margin-bottom: 0;
	}
	.pattern-div {
		margin-top: 35px;
	}
	.history-col:first-child {
		margin-bottom: 140px;
	}
	.history-col:nth-last-child(2) .pattern-div {
		margin-top: 30px;
	}
	.history-col:first-child .pattern-div {
		margin-top: 30px;
	}
	.history-col h4 {
		font-size: 22px;
		margin-bottom: 20px;
	}

	.support-sec {
		padding: 50px 0 30px;
	}
	.sub-info-col {
		padding-top: 50px;
	}
	.info-col {
		padding: 0 15px;
	}
	.info-col p {
		margin-bottom: 20px;
	}
	.content-wrap {
		padding-bottom: 80px;
	}
	.support-sec + .support-sec {
		padding-top: 20px;
	}
	.support-sec + .support-sec .content-wrap {
		padding-bottom: 100px;
	}
	.info-col .btn {
		margin-top: 5px;
	}

	.inner-page-content {
		padding: 60px 0;
	}
	.inner-page-content h2 {
		letter-spacing: 0.5px;
	}
	.inner-page-content p + h2 {
		margin-top: 40px;
	}
	/* order */
	.subscription-section .row {
		justify-content: center;
	}
	.subscription-col {
		margin-top: 20px;
	}
	.subscription-section {
		padding: 50px 0;
	}
	.subscription-section .col-md-6:last-child .subscription-col {
		margin-bottom: 0;
	}
	.step-chart-content {
		padding: 80px 0 80px;
	}
	.div-content p {
		font-size: 15px;
		letter-spacing: 0;
	}
	.div-content {
		padding: 10px 12px 0 0;
	}
	.step-content-row,
	.step-content-row.step-content-row-field {
		margin-bottom: 30px;
	}
	.step-content-row:last-child,
	.step-content-row.step-content-row-field:last-child {
		margin-bottom: 0;
	}
	.step-chart-wrapper {
		padding-bottom: 80px;
	}
	.circle-step {
		width: 42px;
		height: 42px;
		border-width: 4px;
	}
	.step-done .circle-step:after {
		background-size: 42px;
	}
	.step-chart-sec {
		padding: 155px 0 0;
	}
	.step-chart-content {
		padding: 110px 0 110px;
	}
	.custom-radio {
		margin-right: 15px;
	}
	.custom-radio label {
		min-width: 130px;
	}
	.step-content-right p {
		font-size: 16px;
		letter-spacing: 0;
	}
	.three-text-box .form-field {
		width: 29% !important;
	}
	textarea,
	input[type='text'],
	input[type='tel'],
	input[type='email'],
	input[type='password'],
	input[type='date'],
	input[type='search'],
	textarea,
	select {
		font-size: 16px;
		line-height: 18px;
	}
	/*    .form-field-wrapper {padding: 15px 0 0;}*/
	.form-field {
		margin-bottom: 12px;
	}
	.order-info-right p {
		margin-bottom: 10px;
	}
	.order-info-right {
		padding: 30px;
	}
	.order-info-right p:nth-last-child(2) {
		margin-top: 40px;
	}
	.term-agree p {
		margin-bottom: 15px;
	}
	.order-confirmation .next-step {
		margin-top: 0;
	}
	.step-box-heading h2 {
		margin-bottom: 30px;
	}
	.blue-step-box {
		padding: 50px 0;
	}
	.box-content h4 {
		font-size: 19px;
	}
	/*order*/
	.home-article-sec {
		padding: 80px 0 20px;
	}
	.article-img {
		width: 250px;
		height: 250px;
	}
	.article-content {
		width: calc(100% - 250px);
		padding-right: 30px;
	}
	.home-article-main:nth-child(even) .article-content {
		padding-right: 80px;
	}
	.article-btn {
		margin-top: 80px;
	}
	.content-inner {
		padding-top: 70px;
	}
	.review-page {
		padding: 80px 0 0;
	}
	.review-content-small {
		width: 35%;
		padding: 30px 0;
	}
	.review-content-large {
		width: 65%;
	}
	.review-content-head h2 {
		font-size: 34px;
		margin-bottom: 10px;
	}
	.review-content-small:before,
	.review-content-small:after,
	.review-content-head:before,
	.review-content-head:after,
	.review-content-info:before,
	.review-content-info:after {
		height: 4px;
	}
	.review-content-info p {
		font-size: 18px;
	}
	.review-content-large p span {
		font-size: 18px;
	}
	.review-content-info {
		padding: 20px 0;
	}
	.review-page-content:nth-child(even) {
		padding-right: 0;
	}
	.review-page-content:nth-child(even)
		.review-content-large
		.review-content-head {
		max-width: 380px;
	}
	.review-img {
		width: 240px;
		height: 240px;
	}
	.review-page-content {
		margin-bottom: -10px;
	}
	.review-content-large {
		margin-top: 50px;
	}

	.article-left {
		padding-right: 50px;
	}
	.article-left {
		width: 64%;
	}
	.article-right {
		width: 36%;
	}
	.review-article-main {
		padding: 80px 0 0;
	}
	.article-img-col .review-img {
		margin-bottom: 30px;
	}
	.article-img-col h5 span {
		margin-top: 10px;
	}
	.article-right-inner {
		margin-bottom: 40px;
	}
	.article-left h2 {
		margin-bottom: 15px;
	}
	.article-left p strong {
		margin-bottom: 15px;
	}
	/*.article-img-col .review-img {*/
	/*	margin-bottom: 20px;*/
	/*}*/
	.article-img-col h5 {
		letter-spacing: 0;
	}
	
	.site-navigation ul ul li a { padding: 12px 15px; } 
	.site-navigation ul li a, .site-navigation ul li span {
		padding: 0 8px 11px;
	}
	.header-top-bar {
		margin: -30px 0px 30px;
	}
	.header-top-bar p {
		font-size: 14px;
	}
	
	.header-top-bar p a {
		font-size: 14px;
		margin-left: 11px;
	}
	.header-top-bar p a { font-size: 14px; margin-left: 11px; }
	.video-btn {
		max-width: 80px;
		margin-top: -51px;
	}
	.video-btn img {width: 80px;}
}


@media (max-width: 767px) {
	.btn {
		font-size: 16px;
		font-weight: 500;
		line-height: 18px;
		min-width: 272px;
		padding: 11px 15px 9px;
	}
	h1 {
		font-size: 34px;
	}
	h2 {
		font-size: 30px;
		letter-spacing: 1px;
	}
	h4 {
		font-size: 16px;
	}

	.header-main {
		padding: 8px 6px;
	}
	.header-navigation {
		width: calc(100% - 95px);
	}

	.logo {
		width: 95px;
	}
	.navigation-wrap {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background: #444;
		color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100vh;
		padding: 0 28px;
		height: 0;
		transition: height 0.5s;
		-webkit-transition: height 0.5s;
		-moz-transition: height 0.5s;
		-ms-transition: height 0.5s;
		-o-transition: height 0.5s;
	}
	.open-toggle .navigation-wrap {
		height: 100vh;
	}
	.site-navigation,
	.lang-dropdown {
		display: none;
	}
	.open-toggle .site-navigation,
	.open-toggle .lang-dropdown {
		display: block;
	}

	.header-wrap {
		align-items: center;
		position: static;
	}
	.menu-icon {
		width: 50px;
		margin-left: auto;
		margin-top: auto;
		margin-bottom: auto;
		cursor: pointer;
		position: relative;
		z-index: 1;
		padding: 15px 0px 15px 15px;
	}
	.menu-icon span {
		text-align: left;
		width: 34px;
		height: 3px;
		background: #444444;
		display: block;
		border-radius: 30px;
		margin-bottom: 7px;
	}
	.menu-icon span:nth-child(2) {
		width: 25px;
	}
	.menu-icon span:nth-child(3) {
		width: 14px;
	}
	.open-toggle .menu-icon span:nth-child(1) {
		background: #fff;
		transform: rotate(45deg) translateY(2px) translateX(11px);
		-webkit-transform: rotate(45deg) translateY(2px) translateX(11px);
		width: 22px;
		-moz-transform: rotate(45deg) translateY(2px) translateX(11px);
		-ms-transform: rotate(45deg) translateY(2px) translateX(11px);
		-o-transform: rotate(45deg) translateY(2px) translateX(11px);
	}
	.open-toggle .menu-icon span:nth-child(2) {
		background: #fff;
		opacity: 0;
	}
	.open-toggle .menu-icon span:nth-child(3) {
		background: #fff;
		transform: rotate(-45deg) translateY(-3px) translateX(12px);
		-webkit-transform: rotate(-45deg) translateY(-3px) translateX(12px);
		width: 22px;
		-moz-transform: rotate(-45deg) translateY(-3px) translateX(12px);
		-ms-transform: rotate(-45deg) translateY(-3px) translateX(12px);
		-o-transform: rotate(-45deg) translateY(-3px) translateX(12px);
	}

	.site-navigation ul li {
		display: block;
		padding: 0;
		text-align: center;
		padding: 16px 0;
	}
	.site-navigation ul li a,
	.site-navigation ul li span {
		font-size: 21px;
		font-weight: 500;
		text-transform: uppercase;
		padding: 0 25px 0;
	}
	.lang-dropdown {
		width: 100%;
		text-align: center;
	}
	.site-navigation {
		width: 100%;
		padding: 41px 0 65px;
	}
	.site-navigation ul li {
		overflow: hidden;
	}
	.site-navigation ul li.is-active > ul {
		visibility: visible;
		max-height: 150px;
		height: auto;
		margin-top: 20px;
	}
	.site-navigation ul ul li a {
		color: #000;
	}
	.site-navigation ul ul {
		max-height: 0;
		visibility: hidden;
		transition: all 0.5s ease-in-out;
		transform: none;
		margin-top: 0px;
	}
	.site-navigation ul li span { 
		padding-right: 36px;
		transition: all 0.5s ease-in-out;
	}
	.site-navigation ul li:hover > a,
	.site-navigation ul li:hover > span { 
		color: #02aca0;
	}
	.lang-label {
		max-width: 300px;
		width: 100%;
		min-width: 1px;
		text-align: right;
	}
	.lang-label label {
		border-bottom-color: #fff;
		font-size: 18px;
		letter-spacing: 0;
		padding: 10px 38px 10px 15px;
		font-size: 18px;
	}
	.lang-label label:after {
		border-color: #fff transparent transparent transparent;
	}
	.lang-label label:after {
		right: 10px;
		top: 3px;
	}
	#menu-toggle:checked + label,
	.lang-list {
		background: #616161;
	}
	.lang-list li a {
		border-bottom-color: #979797;
		padding: 12px 34px 11px;
	}
	.logo a img.App-logo {
		display: none;
	}
	.logo a img.mobile-logo {
		display: block;
	}
	.open-language .lang-label label {
		background: #616161;
	}
	.lang-label label,
	.lang-list ul li {
		font-size: 18px;
	}

	.banner-wrap {
		padding: 20px 10px 54px;
	}
	.banner-left {
		text-align: center;
		padding-top: 0;
		min-height: 1px;
		padding-right: 15px;
	}
	.banner-right {
		display: none;
	}
	.main-banner h1 {
		font-size: 36px;
		margin-bottom: 12px;
	}
	.main-banner {
		padding: 76px 0 0;
	}
	.main-banner p,
	.inner-banner p,
	.history-head p {
		font-size: 18px;
		line-height: 1.35;
	}
	.main-banner .btn {
		margin-top: 18px;
	}

	.blue-content-box {
		padding: 33px 0 49px;
	}
	.blue-content-box h2 {
		margin-bottom: 21px;
	}
	.left__blue__content {
		padding-right: 0;
		margin-bottom: 15px;
	}
	/*
	.blue-content-box h2 span {
		font-size: 30px;
		letter-spacing: 1px;
	}
*/
	/*
	.blue-content-box p {
		font-size: 16px;
		width: 100%;
	}
*/

	.service-sec {
		padding: 29px 0 23px;
	}
	.service-col:nth-child(1) {
		order: 1;
	}
	.service-col:nth-child(2) {
		order: 3;
	}
	.service-col:nth-child(3) {
		order: 2;
	}
	.col-img {
		width: 80px;
		height: 80px;
		line-height: 80px;
		margin-bottom: 23px;
	}
	.col-img img {
		max-width: 60px;
		max-height: 60px;
	}
	.inner-blue-box .col-img img {
		max-width: 50px;
	}
	.icon-col {
		margin: 23px auto;
	}

	.single-ic-sec {
		padding: 39px 0 9px;
	}
	.single-ic-sec .image-right-wrap:after {
		display: none;
	}
	.image-right-wrap {
		order: -1;
		padding-top: 0;
	}
	.image-right {
		width: 80px;
		height: 80px;
		line-height: 80px;
	}
	.image-right img {
		max-width: 50px;
	}
	.single-ic-sec ol {
		margin-top: 25px;
		padding-right: 0px;
	}
	.single-ic-sec ol li {
		margin-bottom: 13px;
	}
	.single-ic-sec ol li:before {
		margin-right: 13px;
	}

	.content-sec {
		padding: 42px 0 40px;
	}
	.content-inner {
		padding-bottom: 56px;
		border-bottom-width: 5px;
	}
	.content-left {
		text-align: center;
		padding: 26px 15px 27px;
	}
	.content-sec .col-12 {
		padding: 0 21px;
	}
	.content-inner > .btn {
		margin-top: 60px;
	}

	.ic-image {
		display: none;
	}

	.two-col-section {
		padding-top: 10px;
		padding-bottom: 120px;
	}
	.two-col-section h2 {
		text-align: center;
		margin-bottom: 43px;
	}
	.left-col {
		text-align: center;
		margin-bottom: 40px;
	}
	.right-content {
		padding: 0;
		width: 100%;
	}

	.right-content h5 {
		margin-bottom: 20px;
	}
	.two-col-section h5 + h3 {
		margin-bottom: 13px;
	}
	.two-col-section .download-link {
		margin-top: 5px;
	}
	.download-link img {
		margin-right: 8px;
		width: 12px;
	}
	.right-col {
		padding-left: 7px;
		text-align: center;
	}

	.footer-col {
		min-width: 1%;
		width: 50%;
		max-width: 100%;
	}

	.footer-col:nth-child(1) {
		order: 1;
	}
	.footer-col:nth-child(2) {
		order: 3;
	}
	.footer-col:nth-child(3) {
		order: 4;
		text-align: right;
		margin-top: -26px;
	}
	.footer-col:nth-child(4) {
		order: 2;
	}

	.footer-top,
	.footer-bottom {
		padding: 0 7px;
		align-items: flex-start;
	}

	.footer-main {
		padding-top: 33px;
	}
	.footer-main ul li {
		margin-bottom: 8px;
	}
	.footer-main ul li a {
		font-size: 14px;
	}
	.footer-col > a {
		margin: -2px 7px;
		width: 28px;
		vertical-align: top;
		line-height: 1;
	}

	.footer-bottom {
		padding-top: 35px;
		padding-bottom: 41px;
	}
	.footer-bottom p {
		font-size: 14px;
		width: 97%;
	}

	.footer-copyright {
		padding: 4px 19px 4px;
		line-height: 1;
	}
	.footer-copyright p {
		letter-spacing: 0.4px;
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
	}
	.footer-copyright p span {
		font-size: 18px;
		margin-right: 3px;
	}

	.accordian-title h4 {
		font-size: 18px;
	}
	.accordian-title {
		padding: 18px 20px;
	}
	.accordian-col {
		margin-bottom: 15px;
	}
	.accordian-title:before,
	.accordian-title:after {
		background-size: 20px;
		right: 20px;
	}
	.accordian-content {
		padding: 54px 30px 0px;
		margin-top: -54px;
	}
	.accordian-title.active + .accordian-content {
		padding: 92px 30px 20px;
	}

	.inner-banner {
		padding: 100px 0 40px;
	}
	.inner-banner .container {
		min-height: 1px;
	}
	.inner-banner h1 {
		margin-bottom: 10px;
	}
	.inner-banner p {
		width: 80%;
	}

	.history-head {
		width: 100%;
		padding: 50px 0 40px;
	}
	.history-page {
		padding-top: 76px;
	}
	.hcp-page {
		padding-top: 76px;
	}
	.history-head h1 {
		margin-bottom: 15px;
	}
	.history-content {
		width: 100%;
		padding-top: 40px;
		padding-bottom: 60px;
	}
	.circle-div {
		width: 20px;
		height: 20px;
	}
	.circle-div:before {
		border-width: 4px;
	}
	.show-section .circle-div:after {
		border-top-width: 4px;
		border-left-width: 4px;
		border-right-width: 4px;
	}
	.pattern-div {
		width: 20px;
	}
	.vertical-line-div {
		width: 4px;
	}
	.horizontal-line-div {
		height: 4px;
	}
	.show-section .vertical-line-div {
		height: 45px;
	}
	.history-col {
		margin-bottom: 100px;
	}
	.history-col:first-child {
		margin-bottom: 110px;
	}
	.history-col:first-child .pattern-div,
	.history-col:nth-last-child(2) .pattern-div {
		margin-top: 22px;
	}
	.pattern-div {
		margin-top: 25px;
	}

	.support-inner {
		width: 100%;
	}
	.info-col {
		padding: 0 0 40px;
		width: 100%;
		text-align: center;
	}
	.info-col:last-child {
		padding-bottom: 0;
	}
	.content-wrap {
		padding-bottom: 50px;
	}
	.content-wrap:after {
		width: calc(100% - 42px);
	}
	.sub-info-col {
		padding-top: 30px;
	}
	.info-col h3 {
		margin-bottom: 11px;
	}
	.info-col h4 {
		font-size: 16px;
	}
	.info-col a {
		margin-bottom: 5px;
	}
	.info-col .download-link + h4 {
		margin-top: 15px;
	}
	.info-col .btn + h4 {
		margin-top: 35px;
	}
	.support-sec + .support-sec .content-wrap {
		padding-bottom: 50px;
	}
	.info-link {
		text-align: center;
	}
	.info-link a {
		margin-left: auto;
		margin-right: auto;
	}
	.info-col .download-link {
		margin-top: 15px;
		margin-left: auto;
		margin-right: auto;
	}

	.inner-page-content {
		padding: 40px 0;
	}
	.inner-page-content p + h2 {
		margin-top: 30px;
	}
	.inner-page-content p + h3 {
		margin-top: 20px;
	}
	/* order*/
	.blue-step-box {
		padding: 60px 0 30px;
	}
	.box-image {
		margin-bottom: 20px;
	}
	.box-content h4 {
		margin-bottom: 15px;
	}
	.box-content {
		margin-bottom: 30px;
	}
	.subscription-col {
		display: table;
		vertical-align: middle;
		margin: 0 auto 50px;
		width: 70%;
	}
	.subscription-col h4 {
		margin-bottom: 20px;
	}
	.subscription-col h2 span {
		font-size: 20px;
		line-height: 1;
	}
	.subscription-button .btn {
		width: auto;
		min-width: 272px;
	}
	.next-step {
		padding: 60px 0 40px;
	}
	.subscription-section {
		padding: 50px 0 30px;
	}
	.step-content-left {
		width: 100%;
		padding-right: 0;
		margin-bottom: 20px;
	}
	.step-content-right {
		width: 100%;
		padding-left: 0;
	}
	.next-step .btn {
		min-width: 180px;
	}
	.arrow-btn:after {
		right: 5px;
		background-size: 10px;
	}
	.step-chart-content {
		padding: 60px 0 50px;
	}
	.div-content {
		padding: 0 0 10px;
	}
	/*    .form-field-wrapper {padding: 15px 0 0;}*/
	.form-field {
		margin-bottom: 12px;
	}
	.step-content-right .div-content {
		padding-top: 20px;
	}
	.order-confirmation {
		padding: 60px 0 50px;
	}
	.order-info-right {
		width: 100%;
		margin-top: 60px;
	}
	.step-chart-sec .container {
		max-width: 100%;
	}
	.step-chart-wrapper {
		padding-bottom: 60px;
	}
	.step-chart-wrapper {
		padding-bottom: 60px;
	}
	.step-chart-sec {
		padding: 115px 0 0;
	}
	.step-chart-wrapper:after {
		width: calc(100% - 30px);
	}
	.div-content.bottom-para {
		height: auto;
	}
	.step-content-right .div-content.bottom-para .bottom-para-p {
		margin-top: 20px;
	}
	.box-content h4 {
		font-size: 16px;
	}
	.div-content.div-content-other .bottom-para-p {
		margin-bottom: 0;
	}
	.vat-form .half_width {
		width: 100%;
	}
	/* order*/

	.home-article-main {
		display: block;
		margin-bottom: 50px;
	}
	.article-img {
		margin: 0 auto 30px;
		width: 193px;
		height: 193px;
	}
	.article-content {
		width: 100%;
		padding: 0;
		margin: 0;
		text-align: center;
	}
	.home-article-sec {
		padding: 50px 0 19px;
	}
	.article-content .border-link {
		margin-top: 0;
	}
	.article-content p {
		margin-bottom: 7px;
	}
	.home-article-main:nth-child(even) .article-content {
		padding: 0;
	}
	.article-btn {
		margin-top: 61px;
	}
	.content-inner {
		padding-top: 53px;
	}
	.content-sec h2 {
		line-height: 1.6;
		margin-bottom: 0;
	}

	.review-content-small {
		width: 100%;
		text-align: center;
	}
	.review-content-large {
		width: 100%;
		padding: 0;
		margin-top: 28px !important;
	}
	.review-page-content:nth-child(even) .review-content-small {
		width: 100%;
	}
	.review-page-content:nth-child(even) .review-content-large {
		width: 100%;
		order: inherit;
	}
	.review-page-content {
		margin-bottom: 28px;
	}
	.review-content-info {
		max-width: 100%;
	}
	.review-page-content:nth-child(even)
		.review-content-large
		.review-content-head {
		max-width: 100%;
	}
	.review-content-head h2 {
		font-size: 30px;
	}
	.review-content-info p {
		font-size: 17px;
	}
	.review-content-large p span {
		font-size: 17px;
	}
	.review-page + .content-sec {
		margin-bottom: 50px;
	}
	.review-img {
		margin: 0 auto 20px;
	}
	.article-left {
		width: 100%;
		padding: 0;
	}
	.article-right {
		width: 100%;
		margin-top: 20px;
		text-align: center;
	}
	.review-article-main {
		padding: 70px 0 0;
	}
	.article-left p + h3 {
		margin-top: 15px;
	}

	.site-navigation ul ul {
		position: static;
	}
	.site-navigation ul li span:after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 25px;
		height: 25px;
		margin: auto;
		background: url('../images/plus.png');
		background-size: 13px;
		background-repeat: no-repeat;
		background-position: center center;
	}
	.site-navigation ul li.is-active span:after {
		background: url('../images/minus.png');
		background-size: 13px;
		background-repeat: no-repeat;
		background-position: center center;
	}
	.header-top-bar {
		margin: -8px -6px 10px;
	}
	
	
}

@media (max-width: 639px) {
	.history-head {
		padding-top: 30px;
	}
	/* order */
	.custom-radio {
		margin-right: 10px;
	}
	.custom-radio label {
		min-width: 125px;
	}
	.step-chart-sec .container {
		max-width: 100%;
	}
	.step-col p {
		display: none;
	}
	.circle-step {
		width: 35px;
		height: 35px;
		border-width: 3px;
	}
	.circle-step-line {
		bottom: 14px;
	}
	/* order */
	.review-article-main {
		padding: 50px 0 0;
	}
	.article-img-col h5 {
		font-size: 16px;
	}
}

@media (max-width: 575px) {
	h1 {
		font-size: 30px;
	}
	h3 {
		font-size: 18px;
		letter-spacing: 0;
	}
	h5 {
		font-size: 12px;
	}
	.content-left li {
		font-size: 9px;
	}
	.right-col h5 {
		font-size: 10px;
		margin-bottom: 10px;
	}
	.right-col p {
		font-size: 14px;
		letter-spacing: -0.3px;
		margin-bottom: -1px;
		line-height: 1;
	}
	.right-col .download-link {
		font-size: 10px;
		margin-top: 0;
	}
	.right-col .download-link img {
		width: 9px;
		margin-top: -2px;
		margin-right: 6px;
	}
	.right-col .download-link + h5 {
		margin-top: 10px;
	}
	.custom-scrollbar {
		max-height: 130px;
	}

	.footer-main ul li a,
	.footer-bottom p {
		font-size: 12px;
	}
	.footer-bottom p {
		line-height: 1.35;
	}
	.footer-copyright p {
		font-size: 9px;
	}

	.accordian-title {
		padding: 15px 20px;
	}
	.accordian-title h4 {
		font-size: 16px;
	}

	.inner-banner p {
		width: 100%;
	}
	.support-sec + .support-sec .content-wrap {
		padding-bottom: 30px;
	}

	.accordian-content {
		padding: 46px 30px 0px;
		margin-top: -46px;
	}
	.accordian-title.active + .accordian-content {
		padding: 74px 30px 20px;
	}
	.content-sec h2 {
		max-width: 220px;
		margin-left: auto;
		margin-right: auto;
	}

	.accordian-content {
		border-top-right-radius: 25px;
		border-top-left-radius: 25px;
	}
	.accordian-title h4 {
		padding-right: 30px;
	}
	/*order*/
	.next-step {
		display: block;
	}
	.next-step .btn {
		display: table;
		margin: 0 auto 30px;
	}
	.next-step .btn:last-child {
		margin-bottom: 0;
	}
	.popup-msg {
		width: 100%;
	}
	.circle-step {
		width: 30px;
		height: 30px;
	}
	.step-done .circle-step:after {
		background-size: 30px;
	}
	.step-done .circle-step {
		border-width: 0;
	}
	.step-chart-wrapper {
		padding-bottom: 40px;
	}
	.step-chart-content {
		padding: 40px 0 30px;
	}
	/*order*/
	
	.header-top-bar p {
		font-size: 13px;
		display: block;
		margin-bottom: 6px;
	}
	
	.header-top-bar p a {
		font-size: 13px;
		margin-left: 11px;
	}
	
	.header-top-bar p:last-child {
		margin-bottom: 0;
	}
	
	.header-top-bar p a:first-child {
		margin-left: 0;
	}
	.main-banner { padding: 116px 0 0; }
}
/***** Responsive style ends here *****/
.popup-img {
	width: 100%;
	background-size: cover;
	background-position: center center;
	margin-left: auto;
	padding-bottom: 56%;
	position: relative;
	cursor: pointer;
	background-repeat: no-repeat;
}
.popup-img img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%);
	margin-top: -35px;
}
.popup-img video {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: 0;
}
.left__blue__content {
	padding-right: 50px;
}
.popup__wrapper .modal-video-close-btn {
	min-width: 1px;
	border-radius: 0;
	box-shadow: none;
	right: 0;
	padding: 0;
}
.popup__wrapper .modal-video {
	overflow: auto;
}
.popup__wrapper .modal-video-body {
	padding: 55px 15px;
}
.popup__wrapper .modal-video {
	cursor: auto;
}
.model-open,
.model-open .Routes {
	overflow: hidden;
	max-height: 100vh;
}
.modal-video1 video {
	width: 100%;
}
.modal-video1 {
	max-width: 800px;
	padding: 15px;
	overflow: auto;
	max-height: 100vh;
}
.close-btn {
	display: flex;
	justify-content: flex-end;
	color: white;
	font-size: 28px;
	font-weight: 500;
	cursor: pointer;
}
